import React, { useRef, useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';

import { Divider, Grid, Typography, colors, Button, ButtonGroup, Paper } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';

import { useTheme } from '@mui/material/styles';

import ContainerLoading from '../Loading/ContainerLoading';

import HttpContext from '../../contexts/HTTP/HttpContext';
import AuthContext from '../../contexts/Auth/AuthContext';
import DeviceGroupDialog from '../Dialogs/DeviceGroupDialog';
import ReassignDevicesDialog from '../Dialogs/ReassignDevicesDialog';
import BulkUpdateDeviceDialog from '../Dialogs/BulkUpdateDeviceDialog';
import DeviceConfigurationDialog from '../../components/Dialogs/DeviceConfigurationDialog';
import CaptureDeviceGroupWidget from '../CaptureDeviceGroupWidget';
import StreamDialog from '../Dialogs/StreamDialog';
import DeviceHistoryDialog from '../Dialogs/DeviceHistoryDialog';

import { useConfirm } from 'material-ui-confirm';
import MobileContext from '../../contexts/Mobile/MobileContext';
import { isAdmin } from '../../utils/misc';
import SnackbarContext from '../../contexts/Snackbar/SnackbarContext';
import UpdateDeviceDialog from '../Dialogs/UpdateDeviceDialog';

const unassignedGroupId = 'UNASSIGNED';

function Home(props) {
    const theme = useTheme();
    const confirm = useConfirm();
    const { get, postJSON } = useContext(HttpContext);
    const { user } = useContext(AuthContext);
    const { isMobile } = useContext(MobileContext);
    const onSnackbar = useContext(SnackbarContext);

    const [loading, setLoading] = useState(false);

    const [deviceGroupDialogOpen, setDeviceGroupDialogOpen] = useState(false);
    const [deviceGroupToEdit, setDeviceGroupToEdit] = useState(null);

    const [bulkUpdateDeviceDialogOpen, setBulkUpdateDeviceDialogOpen] = useState(false);
    const [reassignDevicesDialogOpen, setReassignDevicesDialogOpen] = useState(false);

    const [deviceConfigDialogGroup, setDeviceConfigDialogGroup] = useState(null);
    const [deviceConfigDialogOpen, setDeviceConfigDialogOpen] = useState(false);

    const [streamDialogOpen, setStreamDialogOpen] = useState(false);
    const [deviceToStream, setDeviceToStream] = useState(null);

    const [deviceHistoryDialogOpen, setDeviceHistoryDialogOpen] = useState(false);
    const [deviceToViewHistory, setDeviceToViewHistory] = useState(null);

    const [updateDeviceDialogOpen, setUpdateDeviceDialogOpen] = useState(false);
    const [captureDeviceToUpdate, setCaptureDeviceToUpdate] = useState(null);

    const [captureDeviceGroups, setCaptureDeviceGroups] = useState([]);
    const [unassignedCaptureDevices, setUnassignedCaptureDevices] = useState([]);
    const [captureDeviceSelection, setCaptureDeviceSelection] = useState({});

    const handleLoadDeviceGroups = async () => {
        setLoading(true);
        const res = await get('/captureDeviceGroups/all');
        if (res.status === 200) {
            setCaptureDeviceGroups(res.data.captureDeviceGroups);
            setUnassignedCaptureDevices(res.data.unassignedCaptureDevices);
        }
        setLoading(false);
    };

    const handleCreateGroupBtn = () => {
        setDeviceGroupToEdit(null);
        setDeviceGroupDialogOpen(true);
    };

    const handleResetSelection = () => {
        setCaptureDeviceSelection({});
    };

    const handleDeviceGroupDialogSubmit = async (group, user) => {
        let res;
        if (_.isNil(deviceGroupToEdit)) {
            res = await postJSON(`/captureDeviceGroups/create/${user._id}`, group);
        } else {
            res = await postJSON(`/captureDeviceGroups/${deviceGroupToEdit._id}/update`, { ...group, user: user._id });
        }
        if (res.status === 200) {
            await handleLoadDeviceGroups();
            setDeviceGroupDialogOpen(false);
        }
    };

    const handleDeleteDeviceGroup = (group) => {
        confirm({ description: 'Are you sure you want to delete this?' })
            .then(async () => {
                const res = await postJSON(`/captureDeviceGroups/${group._id}/delete`);
                if (res.status === 200) {
                    await handleLoadDeviceGroups();
                }
            })
            .catch(() => {}); //cancel pressed
    };

    const handleBulkUpdateDevice = async (updateObj) => {
        const captureDeviceIds = _(captureDeviceSelection).values().flatten().value();
        const res = await postJSON(`/captureDevices/bulkUpdate`, {
            captureDevices: captureDeviceIds,
            updateObj
        });
        if (res.status === 200) {
            await handleLoadDeviceGroups();
            handleResetSelection();
            setBulkUpdateDeviceDialogOpen(false);
        }
    };

    const handleUpdateDevice = async (updateObj) => {
        const res = await postJSON(`/captureDevices/bulkUpdate`, {
            captureDevices: [_.get(captureDeviceToUpdate, '_id')],
            updateObj
        });
        if (res.status === 200) {
            await handleLoadDeviceGroups();
            setUpdateDeviceDialogOpen(false);
        }
    };

    const handleReassignDevices = async (groupId) => {
        const captureDeviceIds = _(captureDeviceSelection).values().flatten().value();
        let res;
        if (groupId !== unassignedGroupId) {
            res = await postJSON(`/captureDeviceGroups/${groupId}/assignDevices`, {
                captureDevices: captureDeviceIds
            });
        } else {
            res = await postJSON(`/captureDeviceGroups/unassignDevices`, {
                captureDevices: captureDeviceIds
            });
        }
        if (res.status === 200) {
            await handleLoadDeviceGroups();
            setReassignDevicesDialogOpen(false);
            handleResetSelection();
        }
    };

    const handleRowsSelected = (group, newRows) => {
        const captureDeviceSelectionCopy = _.cloneDeep(captureDeviceSelection);

        if (group === null) {
            //unassigned devices selected
            captureDeviceSelectionCopy[unassignedGroupId] = newRows;
        } else {
            captureDeviceSelectionCopy[group._id] = newRows;
        }
        setCaptureDeviceSelection(captureDeviceSelectionCopy);
    };

    const handleEditDeviceGroup = (group) => {
        setDeviceGroupToEdit(group);
        setDeviceGroupDialogOpen(true);
    };

    const handleRegisterDeviceDialog = (deviceGroup) => {
        setDeviceConfigDialogGroup(deviceGroup);
        setDeviceConfigDialogOpen(true);
    };

    const handleRegisterDevice = async (deviceId) => {
        const res = await postJSON(`/captureDevices/create`, {
            captureDevice: { deviceId },
            group: _.get(deviceConfigDialogGroup, '_id')
        });
        if (res.status === 200) {
            await handleLoadDeviceGroups();
            onSnackbar('Successfully registered device');
            setDeviceConfigDialogOpen(false);
        } else {
            if (_.get(res, 'data.message')) {
                onSnackbar(_.get(res, 'data.message'), 'error');
            } else {
                onSnackbar('Error registering device', 'error');
            }
        }
    };

    const handleViewDeviceStream = (captureDevice) => {
        setDeviceToStream(captureDevice);
        setStreamDialogOpen(true);
    };

    const handleViewDeviceHistory = (captureDevice) => {
        setDeviceToViewHistory(captureDevice);
        setDeviceHistoryDialogOpen(true);
    };

    const handleUpdateDialog = (captureDevice) => {
        setCaptureDeviceToUpdate(captureDevice);
        setUpdateDeviceDialogOpen(true);
    };

    useEffect(() => {
        handleLoadDeviceGroups();
    }, []);

    const totalDevicesSelected = useMemo(() => {
        return Object.values(captureDeviceSelection).reduce((total, selectedForGroup) => {
            return total + selectedForGroup.length;
        }, 0);
    }, [captureDeviceSelection]);

    return (
        <div
            style={{
                margin: theme.spacing(isMobile ? 0 : 2),
                padding: theme.spacing(isMobile ? 1 : 3),
                width: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: colors.grey[100],
                borderRadius: '10px'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        <div>
                            <Typography variant="h4" style={{ marginRight: theme.spacing(1) }}>
                                Home
                            </Typography>
                            {/*<Typography>Manage your devices</Typography>*/}
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                onClick={handleCreateGroupBtn}
                                style={{ marginRight: theme.spacing(1) }}
                                size={isMobile ? 'small' : 'medium'}
                            >
                                <Add style={{ marginRight: theme.spacing(1) }} />
                                Create Group
                            </Button>
                            <ButtonGroup variant="contained" color="secondary">
                                <Button
                                    variant="contained"
                                    onClick={() => setBulkUpdateDeviceDialogOpen(true)}
                                    disabled={totalDevicesSelected === 0}
                                    color="secondary"
                                    size={isMobile ? 'small' : 'medium'}
                                >
                                    Configure
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => setReassignDevicesDialogOpen(true)}
                                    disabled={totalDevicesSelected === 0}
                                    color="secondary"
                                    size={isMobile ? 'small' : 'medium'}
                                >
                                    Reassign
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <Divider style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }} />
                </Grid>
                {/*<ContainerLoading show="false" />*/}
                {isAdmin(user) && (
                    <CaptureDeviceGroupWidget
                        deviceGroup={{
                            _id: unassignedGroupId,
                            name: 'Unassigned',
                            captureDevices: unassignedCaptureDevices
                        }}
                        isUnassignedDeviceGroup={true}
                        allDeviceGroups={captureDeviceGroups}
                        rowsSelected={_.get(captureDeviceSelection, unassignedGroupId, [])}
                        onRowsSelected={(rows) => handleRowsSelected(null, rows)}
                        onEditDeviceGroup={handleEditDeviceGroup}
                        onDeleteDeviceGroup={handleDeleteDeviceGroup}
                        onRegisterDevice={handleRegisterDeviceDialog}
                        onViewDeviceStream={handleViewDeviceStream}
                        onViewDeviceHistory={handleViewDeviceHistory}
                        onUpdateDevice={handleUpdateDialog}
                    />
                )}
                {captureDeviceGroups.map((deviceGroup) => {
                    const rowsSelected = _.get(captureDeviceSelection, _.get(deviceGroup, '_id'), []);
                    return (
                        <CaptureDeviceGroupWidget
                            deviceGroup={deviceGroup}
                            allDeviceGroups={captureDeviceGroups}
                            rowsSelected={rowsSelected}
                            onRowsSelected={(rows) => handleRowsSelected(deviceGroup, rows)}
                            onEditDeviceGroup={handleEditDeviceGroup}
                            onDeleteDeviceGroup={handleDeleteDeviceGroup}
                            onRegisterDevice={handleRegisterDeviceDialog}
                            onViewDeviceStream={handleViewDeviceStream}
                            onViewDeviceHistory={handleViewDeviceHistory}
                            onUpdateDevice={handleUpdateDialog}
                        />
                    );
                })}
            </Grid>
            <DeviceGroupDialog
                open={deviceGroupDialogOpen}
                deviceGroupToEdit={deviceGroupToEdit}
                user={user}
                onClose={() => setDeviceGroupDialogOpen(false)}
                onSubmit={handleDeviceGroupDialogSubmit}
            />
            <ReassignDevicesDialog
                open={reassignDevicesDialogOpen}
                deviceGroups={captureDeviceGroups}
                unassignEnabled={isAdmin(user)}
                onClose={() => setReassignDevicesDialogOpen(false)}
                onSubmit={handleReassignDevices}
            />
            <BulkUpdateDeviceDialog
                open={bulkUpdateDeviceDialogOpen}
                onClose={() => setBulkUpdateDeviceDialogOpen(false)}
                handleSubmit={handleBulkUpdateDevice}
            />
            <UpdateDeviceDialog
                captureDevice={captureDeviceToUpdate}
                open={updateDeviceDialogOpen}
                onClose={() => setUpdateDeviceDialogOpen(false)}
                handleSubmit={handleUpdateDevice}
            />
            {deviceConfigDialogOpen && (
                <DeviceConfigurationDialog
                    open={deviceConfigDialogOpen}
                    deviceGroups={captureDeviceGroups}
                    deviceGroup={deviceConfigDialogGroup}
                    onClose={() => setDeviceConfigDialogOpen(false)}
                    onSubmitRegistration={handleRegisterDevice}
                />
            )}

            <StreamDialog
                open={streamDialogOpen}
                captureDevice={deviceToStream}
                onClose={() => setStreamDialogOpen(false)}
            />
            {deviceHistoryDialogOpen && (
                <DeviceHistoryDialog
                    open={deviceHistoryDialogOpen}
                    captureDevice={deviceToViewHistory}
                    onClose={() => setDeviceHistoryDialogOpen(false)}
                />
            )}
        </div>
    );
}

export default Home;

import React, { useContext } from 'react';

import { useTheme } from '@mui/material/styles';
import { ListItemIcon, ListItemText, MenuList, MenuItem, Typography } from '@mui/material';

import VpnKey from '@mui/icons-material/VpnKey';
import Home from '@mui/icons-material/Home';
import ImageIcon from '@mui/icons-material/Image';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';

import AuthContext from '../../contexts/Auth/AuthContext';
import MobileContext from '../../contexts/Mobile/MobileContext';

function Navigation(props) {
    const theme = useTheme();
    const { authenticated } = useContext(AuthContext);
    const { isMobile } = useContext(MobileContext);

    const { visible, handleClose } = props;

    const handleLinkClick = () => {
        if (isMobile) {
            handleClose();
        }
    };

    return (
        <div
            style={{
                width: visible ? '300px' : 0,
                minWidth: visible ? '300px' : 0,
                maxWidth: visible ? '300px' : 0,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                transition: '0.3s',
                overflow: 'hidden'
            }}
        >
            <Typography style={{ fontWeight: 500, margin: theme.spacing(2), marginBottom: 0 }}>General</Typography>
            <MenuList style={{ marginLeft: theme.spacing(2) }}>
                <Link
                    to="/dashboard/home"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    onClick={handleLinkClick}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText>Home</ListItemText>
                    </MenuItem>
                </Link>
            </MenuList>
            {/*<Typography
        style={{ fontWeight: 500, margin: theme.spacing(2), marginBottom: 0 }}
      >
        Other
      </Typography>
      <MenuList style={{ marginLeft: theme.spacing(2) }}>
        {authenticated.roles.includes("admin") && (
          <Link
            to="/dashboard/admin"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleLinkClick}
          >
            <MenuItem>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Admin</ListItemText>
            </MenuItem>
          </Link>
        )}
        {JSON.parse(process.env.REACT_APP_API_KEYS_ENABLED) && (
          <Link
            to="/dashboard/api"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleLinkClick}
          >
            <MenuItem>
              <ListItemIcon>
                <VpnKey />
              </ListItemIcon>
              <ListItemText>API Keys</ListItemText>
            </MenuItem>
          </Link>
        )}
        </MenuList>*/}
        </div>
    );
}

export default Navigation;

import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { getFormikError } from '../../utils/misc';

import { useTheme } from '@emotion/react';
import { Grid, TextField, Typography, Button, CircularProgress } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { EMAIL_REGEX, PASSWORD_REGEX } from '../../constants';

import AuthContext from '../../contexts/Auth/AuthContext';
import HttpContext from '../../contexts/HTTP/HttpContext';
import SnackbarContext from '../../contexts/Snackbar/SnackbarContext';

function AccountInfo(props) {
    const { handleSubmit } = props;

    const theme = useTheme();
    const { user } = useContext(AuthContext);
    const { postJSON } = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            newPassword: '',
            confirmNewPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required('You must enter a value'),
            newPassword: Yup.string()
                .required('You must enter a value')
                .max(32, 'Password can contain at most 32 characters')
                .matches(
                    PASSWORD_REGEX,
                    'Password must contain at least one uppercase character, one lowercase character, and one number'
                ),
            confirmNewPassword: Yup.string()
                .required('You must enter a value')
                .max(32, 'Password can contain at most 32 characters')
                .matches(
                    PASSWORD_REGEX,
                    'Password must contain at least one uppercase character, one lowercase character, and one number'
                )
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        }),
        onSubmit: async (values) => {
            setLoading(true);
            const res = await postJSON(`/users/${user._id}/changePassword`, values);
            console.log('res', res);
            if (res.status === 200) {
                onSnackbar('Successfully updated password');
                formik.handleReset();
            } else {
                //console.log('here', res.data.message);
                onSnackbar(res.data.message, 'error');
            }
            setLoading(false);
        }
    });

    return (
        <div>
            <Typography variant="h6">Password</Typography>
            <TextField
                {...formik.getFieldProps('password')}
                fullWidth
                label="Current Password"
                margin="normal"
                type="password"
                error={getFormikError(formik, 'password')}
                helperText={getFormikError(formik, 'password')}
            />
            <TextField
                {...formik.getFieldProps('newPassword')}
                fullWidth
                label="New Password"
                margin="normal"
                type="password"
                error={getFormikError(formik, 'newPassword')}
                helperText={getFormikError(formik, 'newPassword')}
            />
            <TextField
                {...formik.getFieldProps('confirmNewPassword')}
                fullWidth
                label="Confirm New Password"
                margin="normal"
                type="password"
                error={getFormikError(formik, 'confirmNewPassword')}
                helperText={getFormikError(formik, 'confirmNewPassword')}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(2) }}>
                <Button variant="contained" onClick={formik.handleSubmit} disabled={loading}>
                    {loading && (
                        <CircularProgress size={18} color="inherit" style={{ marginRight: theme.spacing(1) }} />
                    )}
                    Save
                </Button>
            </div>
        </div>
    );
}

export default AccountInfo;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getFormikError } from '../../utils/misc';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Button,
    Grid,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
    OutlinedInput,
    FormHelperText
} from '@mui/material';

import PhoneInput from '../../components/InputComponents/PhoneInput';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useTheme } from '@mui/material/styles';

import { EMAIL_REGEX, PASSWORD_REGEX } from '../../constants';

import HttpContext from '../../contexts/HTTP/HttpContext';

const ERRORS = {
    400: 'An error occurred while processing your registration',
    409: 'An account for this email already exists'
};

function Register({ authenticate }) {
    const theme = useTheme();
    const { post } = useContext(HttpContext);

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [registerError, setRegisterError] = useState('');

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            address: '',
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('You must enter a value'),
            lastName: Yup.string().required('You must enter a value'),
            phone: Yup.string().length(10, 'This must be 10 characters').required('You must enter a value'),
            address: Yup.string().required('You must enter a value'),
            email: Yup.string().required('Email is a required field').matches(EMAIL_REGEX, 'Email format invalid'),
            password: Yup.string()
                .required('Password is a required field')
                .max(32, 'Password can contain at most 32 characters')
                .matches(
                    PASSWORD_REGEX,
                    'Password must contain at least one uppercase character, one lowercase character, and one number'
                )
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setShowPassword(false);

            const res = await post('/register', values); //TODO:
            if (res.status >= 400) {
                setRegisterError(ERRORS[res.status]);
            } else {
                authenticate(res);
                setRegisterError('');
            }

            setLoading(false);
        }
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            formik.handleSubmit();
        }
    };

    return (
        <Grid
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '480px',
                margin: 'auto'
            }}
        >
            <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
                Register for BCIC
            </Typography>
            <Typography style={{ marginBottom: theme.spacing(2) }}>
                Register an account or <Link to={'/login'}>log in</Link>
            </Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}
            >
                <TextField
                    {...formik.getFieldProps('firstName')}
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    style={{
                        marginRight: theme.spacing(0.5),
                        marginBottom: theme.spacing(0.75)
                    }}
                    error={getFormikError(formik, 'firstName')}
                    helperText={getFormikError(formik, 'firstName')}
                />
                <TextField
                    {...formik.getFieldProps('lastName')}
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    style={{
                        marginLeft: theme.spacing(0.5),
                        marginBottom: theme.spacing(0.75)
                    }}
                    error={getFormikError(formik, 'lastName')}
                    helperText={getFormikError(formik, 'lastName')}
                />
            </div>
            <PhoneInput
                {...formik.getFieldProps('phone')}
                variant="outlined"
                label="Phone"
                fullWidth
                style={{ margin: theme.spacing(0.75) }}
                error={getFormikError(formik, 'phone')}
                helperText={getFormikError(formik, 'phone')}
            />
            <TextField
                {...formik.getFieldProps('address')}
                label="Address"
                fullWidth
                style={{ margin: theme.spacing(0.75) }}
                error={getFormikError(formik, 'address')}
                helperText={getFormikError(formik, 'address')}
            />
            <TextField
                fullWidth
                style={{ margin: theme.spacing(0.75) }}
                variant="outlined"
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={loading}
                error={getFormikError(formik, 'email')}
                helperText={getFormikError(formik, 'email')}
                onKeyDown={handleKeyDown}
            />
            <FormControl
                fullWidth
                variant="outlined"
                disabled={loading}
                style={{ margin: theme.spacing(0.75) }}
                error={getFormikError(formik, 'password')}
            >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                    id="password"
                    name="password"
                    label="Password"
                    value={formik.values.password}
                    type={showPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText>{getFormikError(formik, 'password')}</FormHelperText>
            </FormControl>

            <Typography variant="body2" style={{ margin: theme.spacing(1) }}>
                By registering an account you agree to our Terms and Conditions.
            </Typography>

            <Typography variant="body2" style={{ color: 'red' }}>
                {registerError}
            </Typography>

            <Button
                fullWidth
                variant="contained"
                disabled={loading}
                onClick={formik.handleSubmit}
                style={{ margin: theme.spacing(2) }}
            >
                Register
            </Button>
        </Grid>
    );
}

export default Register;

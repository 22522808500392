import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { useState } from 'react';
import _ from 'lodash';

import Pages from './containers/Pages';

import { Snackbar, Alert, ThemeProvider, Slide } from '@mui/material';
import { lightTheme, darkTheme } from './theme';

import { AuthProvider } from './contexts/Auth/AuthContext';
import { HttpProvider } from './contexts/HTTP/HttpContext';
import { MobileProvider } from './contexts/Mobile/MobileContext';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from './contexts/Snackbar/SnackbarContext';

import { get, post, postJSON } from './utils/api';

function App() {
    const [theme, setTheme] = useState(lightTheme);
    const [authenticated, setAuthenticated] = useState(null);
    const [user, setUser] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [snackbarVariant, setSnackbarVariant] = useState('success');

    const onSnackbar = (message, variant = 'success') => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
        setSnackbarVariant(_.toLower(variant));
    };

    // useEffect(() => {
    //   document.body.style.backgroundColor = theme.palette.background.default;
    // });

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider value={{ authenticated, setAuthenticated, user, setUser }}>
                <HttpProvider value={{ get, post, postJSON }}>
                    <MobileProvider value={{ isMobile, setIsMobile }}>
                        <ConfirmProvider>
                            <SnackbarProvider value={onSnackbar}>
                                <Pages />
                                {/*for some reason the snackbar would leave an invisible element after closing preventing buttons underneath 
                                    from being click - only render this component when it is open
                                */}
                                {snackbarOpen && (
                                    <Snackbar
                                        open={snackbarOpen}
                                        TransitionComponent={(props) => <Slide {...props} direction="right" />}
                                        autoHideDuration={5000}
                                        onClose={() => setSnackbarOpen(false)}
                                    >
                                        <Alert
                                            variant="filled"
                                            onClose={() => setSnackbarOpen(false)}
                                            severity={snackbarVariant}
                                            sx={{ width: '100%' }}
                                        >
                                            {snackbarMessage}
                                        </Alert>
                                    </Snackbar>
                                )}
                            </SnackbarProvider>
                        </ConfirmProvider>
                    </MobileProvider>
                </HttpProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;

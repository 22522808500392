import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import InfiniteScroll from 'react-infinite-scroll-component';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    useMediaQuery,
    CircularProgress,
    Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';

import { formatDate } from '../../utils/misc';
import HttpContext from '../../contexts/HTTP/HttpContext';

import {
    WifiOff,
    Wifi,
    MenuBook,
    AccountCircle,
    Pause,
    PlayArrow,
    Refresh,
    Error,
    CheckCircle
} from '@mui/icons-material';
import { maxHeight } from '@mui/system';

function DeviceHistoryDialog(props) {
    const { captureDevice, open, onClose } = props;
    const [logs, setLogs] = useState([]);
    const [totalLogs, setTotalLogs] = useState(null);
    const [pagesLoaded, setPagesLoaded] = useState(0);
    const { get } = useContext(HttpContext);
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleLoadDeviceLogs = async () => {
        const res = await get(`/captureDeviceLogs/${captureDevice._id}?page_size=${25}&page_num=${pagesLoaded}`);
        if (res.status === 200) {
            setLogs([...logs, ...res.data.logs]);
            setTotalLogs(res.data.total);
            setPagesLoaded(pagesLoaded + 1);
        }
    };

    useEffect(() => {
        if (open) {
            setLogs([]);
            setPagesLoaded(0);
            setTotalLogs(null);
            handleLoadDeviceLogs();
        }
    }, [open, captureDevice]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={fullScreen}>
            <DialogContent id="device-history-dialog-content">
                {!_.isNil(totalLogs) && totalLogs === 0 && (
                    <Typography align="center" variant="h6">
                        No History For This Device
                    </Typography>
                )}
                <List dense>
                    <InfiniteScroll
                        dataLength={logs.length}
                        next={handleLoadDeviceLogs}
                        hasMore={logs.length < totalLogs && !_.isNil(totalLogs)}
                        loader={
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        }
                        scrollableTarget="device-history-dialog-content"
                    >
                        {logs.map((captureDeviceLog) => {
                            const { icon, message } = getIconAndMessageForLog(captureDeviceLog);

                            return (
                                <ListItem key={captureDeviceLog._id}>
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText
                                        primary={message}
                                        secondary={formatDate(
                                            _.get(captureDeviceLog, 'createdAt'),
                                            'YYYY MMM D - h:mm a'
                                        )}
                                    />
                                </ListItem>
                            );
                        })}
                    </InfiniteScroll>
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeviceHistoryDialog;

function getIconAndMessageForLog(captureDeviceLog) {
    let icon, message;
    switch (_.get(captureDeviceLog, 'type')) {
        case 'Registration':
            icon = <AccountCircle color="info" />;
            message = `Device registered to ${_.get(captureDeviceLog, 'user.name.first', 'UNKNOWN')} ${_.get(
                captureDeviceLog,
                'user.name.last',
                'UNKNOWN'
            )}`;
            break;

        case 'Network':
            const connected = _.get(captureDeviceLog, 'networkConnected');
            icon = connected ? <Wifi color="info" /> : <WifiOff color="error" />;
            message = connected ? 'Device connected to the server' : 'Device disconnected from the server';
            break;

        case 'Capture Status':
            const paused = _.get(captureDeviceLog, 'capturePaused');
            icon = paused ? <Pause color="secondary" /> : <PlayArrow color="primary" />;
            message = paused ? 'Capture paused' : 'Capture started';
            break;

        case 'Restart':
            icon = <Refresh color="info" />;
            message = 'Device restarted';
            break;

        case 'Issue':
            const issuesResolved = _.get(captureDeviceLog, 'issuesResolved', false);
            icon = issuesResolved ? <CheckCircle color="success" /> : <Error color="error" />;
            message = `Issues ${issuesResolved ? 'resolved' : 'reported'}: ${_.get(captureDeviceLog, 'issues', []).join(
                ', '
            )}`;
            break;

        default:
            icon = <MenuBook />;
            message = '';
            break;
    }
    return { icon, message };
}

import React, { useState, useEffect, useContext, useMemo } from 'react';

import _ from 'lodash';

import { Autocomplete, TextField, CircularProgress, Grid, Box, Typography } from '@mui/material';
import { debounce } from '@mui/material/utils';
import HttpContext from '../../contexts/HTTP/HttpContext';

function UserSelect(props) {
    const { value, onChange, maxUserCount = 10 } = props;
    const { get } = useContext(HttpContext);

    //const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = useMemo(
        () =>
            debounce(async (request, callback) => {
                const res = await get(`/users?page_size=${maxUserCount}&page_num=0&searchTerm=${request.inputValue}`);
                if (res.status === 200) {
                    callback(res.data.users);
                }
            }, 500),
        []
    );

    useEffect(() => {
        let active = true;

        /*if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }*/

        setLoading(true);
        handleSearch({ inputValue }, (results) => {
            if (active) {
                setLoading(false);
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, handleSearch]);

    return (
        <Autocomplete
            filterOptions={(x) => x}
            getOptionLabel={(option) => _.get(option, 'name.first', '') + ' ' + _.get(option, 'name.last', '')}
            options={options}
            loading={loading}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No users"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                onChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="User"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={_.get(option, '_id')}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ wordWrap: 'break-word' }}>
                                <Box component="span">
                                    {_.get(option, 'name.first', '') + ' ' + _.get(option, 'name.last', '')}
                                </Box>

                                <Typography variant="body2" color="text.secondary">
                                    {_.get(option, 'email')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}

export default UserSelect;

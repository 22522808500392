import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { useTheme } from '@emotion/react';
import UserSelect from '../UserSelect/UserSelect';

function DeviceGroupDialog(props) {
    const { open, deviceGroupToEdit, user, onClose, onSubmit } = props;
    const theme = useTheme();
    const isAdmin = _.get(user, 'roles', []).includes('admin');
    const [name, setName] = useState(_.get(deviceGroupToEdit, 'name', ''));
    const [selectedUser, setSelectedUser] = useState(getDefaultSelectedUser(deviceGroupToEdit, isAdmin, user));

    const handleSubmit = () => {
        onSubmit({ name }, selectedUser);
    };

    useEffect(() => {
        if (open) {
            setName(_.get(deviceGroupToEdit, 'name', ''));
            setSelectedUser(getDefaultSelectedUser(deviceGroupToEdit, isAdmin, user));
        }
    }, [open, deviceGroupToEdit, user]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>{_.isNil(deviceGroupToEdit) ? 'Create' : 'Edit'} Device Group</DialogTitle>
            <DialogContent style={{ paddingTop: theme.spacing(2) }}>
                <TextField
                    fullWidth
                    label="Group Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                />
                {isAdmin && (
                    <div style={{ marginTop: theme.spacing(2) }}>
                        <UserSelect value={selectedUser} onChange={(user) => setSelectedUser(user)} />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit} disabled={_.isEmpty(name) || _.isNil(selectedUser)}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeviceGroupDialog;

function getDefaultSelectedUser(deviceGroupToEdit, isAdmin, user) {
    if (!_.isNil(deviceGroupToEdit)) {
        return _.get(deviceGroupToEdit, 'user', user);
    } else if (!isAdmin) {
        return user;
    } else {
        return null;
    }
}

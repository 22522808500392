import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
    TextField,
    useMediaQuery,
    Typography,
    Paper,
    ButtonGroup,
    CircularProgress,
    MenuItem
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';

import { BleClient, numberToUUID, numbersToDataView, dataViewToNumbers } from '@capacitor-community/bluetooth-le';
import { Capacitor } from '@capacitor/core';
import { useTheme } from '@emotion/react';

import AuthContext from '../../contexts/Auth/AuthContext';
import HttpContext from '../../contexts/HTTP/HttpContext';
import SnackbarContext from '../../contexts/Snackbar/SnackbarContext';

//service
const CONFIG_SERVICE_ID = '00000000-4b8c-4e2b-9095-2e24a710799e';

//characteristics
const DEVICE_SERIAL_ID = '00000001-4b8c-4e2b-9095-2e24a710799e';
const TRIGGER_WIFI_SAVE = '00000002-4b8c-4e2b-9095-2e24a710799e';
const WIFI_CONNECTION_STATUS = '00000003-4b8c-4e2b-9095-2e24a710799e';
//each charateristic of a service can only hold one byte array
const SSID1_ID = '00000004-4b8c-4e2b-9095-2e24a710799e';
const PASS1_ID = '00000005-4b8c-4e2b-9095-2e24a710799e';
const SSID2_ID = '00000006-4b8c-4e2b-9095-2e24a710799e';
const PASS2_ID = '00000007-4b8c-4e2b-9095-2e24a710799e';
const SSID3_ID = '00000008-4b8c-4e2b-9095-2e24a710799e';
const PASS3_ID = '00000009-4b8c-4e2b-9095-2e24a710799e';

function DeviceConfigurationDialog(props) {
    const { open, deviceGroups = [], onClose, onSubmitRegistration } = props;

    const theme = useTheme();

    const [submitConfigLoading, setSubmitConfigLoading] = useState(false);
    const [bluetoothEnabled, setBluetoothEnabled] = useState(false);
    //const [statusMsg, setStatusMsg] = useState('Initializing...');
    const [connectionErrMsg, setConnectionErrMsg] = useState('');
    const [status, setStatus] = useState('Initializing');
    const [device, setDevice] = useState(null);

    const [deviceSerial, setDeviceSerial] = useState('');
    const [wifiConfigs, setWifiConfigs] = useState([{ ssid: '', password: '' }]);
    //const [configSubmitErr, setConfigSubmitErr] = useState('');
    /*const [wifiConnectionIp, setWifiConnectionIp] = useState('');
    const [screen, setScreen] = useState(0);
    const [deviceGroupId, setDeviceGroupId] = useState('');

    const { postJSON } = useContext(HttpContext);
    const { user } = useContext(AuthContext);*/
    const onSnackbar = useContext(SnackbarContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDisconnected = async (deviceId) => {
        /*setConnectionErrMsg('Connection with device lost.');
        setStatus('Error');
        setDevice(null);*/
        console.log('disconnected: ' + deviceId);
    };

    const getBluetoothStatus = async () => {
        try {
            await BleClient.initialize({ androidNeverForLocation: true });
            const blueToothEnabled = await BleClient.isEnabled();
            setBluetoothEnabled(blueToothEnabled);
            if (!blueToothEnabled) {
                setConnectionErrMsg('Bluetooth not enabled, enable bluetooth to connect to a device.');
                setStatus('Error');
            } else {
                setBluetoothEnabled(true);
                setStatus('Waiting');
            }
        } catch (err) {
            setConnectionErrMsg(err.message);
            setStatus('Error');
        }
    };

    const handleSelectDevice = async () => {
        try {
            await BleClient.initialize({ androidNeverForLocation: true });
            setStatus(`Scanning`);
            const newDevice = await BleClient.requestDevice({ optionalServices: [CONFIG_SERVICE_ID] }); //TODO:
            if (_.get(newDevice, 'deviceId') !== _.get(device, 'deviceId')) {
                if (!_.isNil(device)) {
                    await BleClient.disconnect(device.deviceId);
                }
                await BleClient.connect(newDevice.deviceId, handleDisconnected);
                /*const services = await BleClient.getServices(newDevice.deviceId);
                setDeviceServices(services);*/
                //console.log(newDevice, services);

                let textDecoder = new TextDecoder();
                const dataView = await BleClient.read(newDevice.deviceId, CONFIG_SERVICE_ID, DEVICE_SERIAL_ID);
                const newDeviceSerial = textDecoder.decode(dataView.buffer);
                setDeviceSerial(newDeviceSerial);
                await BleClient.startNotifications(
                    newDevice.deviceId,
                    CONFIG_SERVICE_ID,
                    WIFI_CONNECTION_STATUS,
                    handleConnectionStatusUpdate(newDeviceSerial)
                );
                setDevice(newDevice);
                setStatus(`Connected`);
            } else {
                setStatus(`Connected`);
            }
        } catch (err) {
            if (err.message !== 'User cancelled the requestDevice() chooser.') {
                setConnectionErrMsg(err.message);
                setStatus('Error');
            } else {
                setStatus('Waiting');
            }
        }
    };

    const handleSubmitConfig = async () => {
        try {
            setSubmitConfigLoading(true);
            let textEncoder = new TextEncoder();

            await BleClient.initialize({ androidNeverForLocation: true });
            await BleClient.connect(device.deviceId, handleDisconnected);

            const ssid1Bytes = textEncoder.encode(_.get(wifiConfigs, `[0].ssid`));
            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, SSID1_ID, numbersToDataView(ssid1Bytes));
            const password1Bytes = textEncoder.encode(_.get(wifiConfigs, `[0].password`));
            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, PASS1_ID, numbersToDataView(password1Bytes));

            const ssid2Bytes = textEncoder.encode(_.get(wifiConfigs, `[1].ssid`));
            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, SSID2_ID, numbersToDataView(ssid2Bytes));
            const password2Bytes = textEncoder.encode(_.get(wifiConfigs, `[1].password`));
            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, PASS2_ID, numbersToDataView(password2Bytes));

            const ssid3Bytes = textEncoder.encode(_.get(wifiConfigs, `[2].ssid`));
            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, SSID3_ID, numbersToDataView(ssid3Bytes));
            const password3Bytes = textEncoder.encode(_.get(wifiConfigs, `[2].password`));
            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, PASS3_ID, numbersToDataView(password3Bytes));

            await BleClient.write(device.deviceId, CONFIG_SERVICE_ID, TRIGGER_WIFI_SAVE, numbersToDataView([1])); //write a 1 to this characteristic to tell it to save the credentials
        } catch (err) {
            //setConfigSubmitErr('Error submitting network configuration');
            onSnackbar('Error submitting network configuration', 'error');
            setSubmitConfigLoading(false);
        }
    };

    const handleConnectionStatusUpdate = (deviceId) => async (dataView) => {
        let textDecoder = new TextDecoder();
        const wifiIp = textDecoder.decode(dataView.buffer);
        //setWifiConnectionIp(wifiIp);
        if (_.isEmpty(wifiIp)) {
            onSnackbar(
                "Coudn't connect to any wifi network, please double-check names and passwords entered.",
                'error'
            );
            //setConfigSubmitErr("Coudn't connect to any wifi network, please double-check names and passwords entered.");
        } else {
            //setConfigSubmitErr('');
            //setScreen(1);
            onSubmitRegistration(deviceId); //don't use device serial here, the BleClient.startNotification callback will be referencing an old value of the state due to how closures work
        }
        setSubmitConfigLoading(false);
    };

    const handleSsid = (e, i) => {
        const newConfig = _.cloneDeep(wifiConfigs);
        _.set(newConfig, `[${i}].ssid`, e.target.value);
        setWifiConfigs(newConfig);
    };

    const handlePassword = (e, i) => {
        const newConfig = _.cloneDeep(wifiConfigs);
        _.set(newConfig, `[${i}].password`, e.target.value);
        setWifiConfigs(newConfig);
    };

    const handleRemove = () => {
        const newConfig = _.cloneDeep(wifiConfigs);
        newConfig.pop();
        setWifiConfigs(newConfig);
    };

    const handleAdd = () => {
        const newConfig = _.cloneDeep(wifiConfigs);
        newConfig.push({ ssid: '', password: '' });
        setWifiConfigs(newConfig);
    };

    useEffect(() => {
        getBluetoothStatus();
    }, []);

    /*useEffect(() => {
        if (!_.isNil(device)) {
        }
    }, [device]);*/

    const { msg, severity } = getMessageForStatus(status, connectionErrMsg, device);

    /*const deviceRegistrationScreen = (
        <>
            <Alert severity="success">Successfully connected to a wifi network.</Alert>
            <Typography style={{ marginTop: theme.spacing(2) }}>
                Select the group you want to add this device to to complete the device registration
            </Typography>

            <TextField
                style={{ marginTop: theme.spacing(2) }}
                label="Group"
                value={deviceGroupId}
                onChange={(e) => setDeviceGroupId(e.target.value)}
                select
                fullWidth
            >
                {deviceGroups.map((deviceGroup) => (
                    <MenuItem key={deviceGroup._id} value={deviceGroup._id}>
                        {_.get(deviceGroup, 'name')}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );*/

    //const screens = [wifiConfigScreen, deviceRegistrationScreen];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={fullScreen}>
            <DialogTitle>{'Device Network Configuration'}</DialogTitle>
            <DialogContent>
                {
                    <>
                        <Alert
                            severity={severity}
                            action={
                                <Button onClick={handleSelectDevice} disabled={!bluetoothEnabled} color="inherit">
                                    Connect
                                </Button>
                            }
                        >
                            {msg}
                        </Alert>
                        {!_.isNil(device) && (
                            <>
                                <Typography style={{ marginTop: theme.spacing(2) }}>
                                    Device Id: {deviceSerial}
                                </Typography>

                                {wifiConfigs.map((config, i) => (
                                    <WifiNetworkWidget
                                        ssid={config.ssid}
                                        password={config.password}
                                        i={i}
                                        onSsid={(e) => handleSsid(e, i)}
                                        onPassword={(e) => handlePassword(e, i)}
                                    />
                                ))}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ButtonGroup variant="outlined">
                                        <Button disabled={wifiConfigs.length <= 1} onClick={handleRemove}>
                                            <Remove />
                                        </Button>{' '}
                                        <Button disabled={wifiConfigs.length >= 3} onClick={handleAdd}>
                                            <Add />
                                        </Button>
                                    </ButtonGroup>
                                </div>

                                {/*<TextField
                    fullWidth
                    label="Wifi Name"
                    margin="normal"
                    value={ssid1}
                    onChange={(e) => setSsid1(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Wifi Password"
                    margin="normal"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                />*/}

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(2)
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmitConfig}
                                        disabled={_.isNil(device) || submitConfigLoading}
                                        style={{ flexShrink: 0 }}
                                    >
                                        {submitConfigLoading && (
                                            <CircularProgress
                                                size={'18px'}
                                                color="inherit"
                                                style={{ marginRight: theme.spacing(1) }}
                                            />
                                        )}
                                        Submit
                                    </Button>
                                    {/*!_.isEmpty(configSubmitErr) && (
                            <Typography
                                color="error"
                                variant="caption"
                                style={{
                                    marginLeft: theme.spacing(2),
                                    display: 'block',
                                    whiteSpace: 'normal',
                                    flexShrink: 1
                                }}
                            >
                                {configSubmitErr}
                            </Typography>
                            )*/}
                                </div>
                            </>
                        )}
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeviceConfigurationDialog;

function getMessageForStatus(status, connectionErrMsg, device) {
    switch (status) {
        case 'Initializing':
            return { msg: 'Initializing...', severity: 'info' };
        case 'Error':
            return { msg: connectionErrMsg, severity: 'error' };
        case 'Waiting':
            return { msg: 'Connect to a device to start.', severity: 'info' };
        case 'Scanning':
            return { msg: 'Scanning...', severity: 'info' };
        case 'Connected':
            return { msg: `Connected to ${_.get(device, 'name', 'Unknown')}`, severity: 'success' };
        default:
            return { msg: 'Click here to connect to a device.', severity: 'info' };
    }
}

function WifiNetworkWidget(props) {
    const { ssid, password, i, onSsid, onPassword } = props;
    const theme = useTheme();
    return (
        <Paper variant="outlined" style={{ padding: theme.spacing(2), marginTop: theme.spacing(2) }}>
            <Typography>Network {i + 1}</Typography>
            <TextField fullWidth label="Wifi Name" margin="dense" value={ssid} onChange={onSsid} />
            <TextField fullWidth label="Wifi Password" margin="dense" value={password} onChange={onPassword} />
        </Paper>
    );
}

function stringToBytes(val) {
    const result = [];
    for (let i = 0; i < val.length; i++) {
        result.push(val.charCodeAt(i));
    }
    return result;
}

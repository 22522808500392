import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Route, Routes, Link } from 'react-router-dom';
import '../../index.css';

import { Grid, IconButton, Menu, MenuItem, Typography, Avatar, Divider } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import Notifications from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Settings from '@mui/icons-material/Settings';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Navigation from '../Navigation/Navigation';

import Home from '../../components/Home/Home';
import APIKeys from '../../components/APIKeys/APIKeys';
import Account from '../../components/Account';

import HttpContext from '../../contexts/HTTP/HttpContext';
import MobileContext from '../../contexts/Mobile/MobileContext';

import { useTheme } from '@mui/material/styles';
import AuthContext from '../../contexts/Auth/AuthContext';

import { useLocation } from 'react-router-dom';

function Dashboard(props) {
    const theme = useTheme();
    const { get } = useContext(HttpContext);
    const { isMobile } = useContext(MobileContext);
    const { user } = useContext(AuthContext);

    const location = useLocation();

    const [showNavigation, setShowNavigation] = useState(!isMobile);

    const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
    const [notificationsMenuAnchor, setNotificationsMenuAnchor] = useState(null);

    const [notifications, setNotifications] = useState([]);

    const logout = async () => {
        await get('/logout');

        localStorage.removeItem('accessToken');
        window.location.href = '/login';
    };

    useEffect(() => {
        const loadNotifications = async () => {
            const res = await get(`/users/${_.get(user, '_id')}/notifications`);
            if (res.status === 200) {
                setNotifications(_.get(res, 'data.notifications', []));
            }
        };

        loadNotifications();
    }, [location, user]);

    return (
        <Grid
            style={{
                margin: 0,
                padding: 0,
                height: '100vh',
                overflow: 'hidden',
                minWidth: '100%',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Grid
                style={{
                    margin: 0,
                    minWidth: '100%',
                    padding: theme.spacing(2),
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        aria-label="toggle navigation menu"
                        onClick={() => setShowNavigation(!showNavigation)}
                        style={{ marginRight: theme.spacing(2) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h4">{process.env.REACT_APP_NAME}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        aria-label="toggle notifications"
                        onClick={(e) => setNotificationsMenuAnchor(e.currentTarget)}
                        style={{
                            marginRight: theme.spacing(2)
                        }}
                        className={notifications.length > 0 ? 'pulsating-icon' : ''}
                    >
                        {notifications.length > 0 ? (
                            <NotificationsActiveIcon style={{ color: 'white' }} />
                        ) : (
                            <Notifications />
                        )}
                    </IconButton>
                    <Menu
                        anchorEl={notificationsMenuAnchor}
                        open={Boolean(notificationsMenuAnchor)}
                        onClose={() => {
                            setNotificationsMenuAnchor(null);
                            /*setTimeout(() => {
                                setNotifications([]);
                            }, 100);*/
                        }}
                    >
                        {notifications.length > 0 &&
                            notifications.map((notification) => {
                                return <MenuItem>{notification}</MenuItem>;
                            })}
                        {notifications.length == 0 && <MenuItem>No new notifications!</MenuItem>}
                    </Menu>
                    {/*<IconButton
                        aria-label="toggle settings"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        style={{ marginRight: theme.spacing(2) }}
                    >
                        <Settings />
                    </IconButton>*/}
                    <IconButton
                        aria-label="toggle account"
                        onClick={(e) => setAccountMenuAnchor(e.currentTarget)}
                        style={{ marginRight: theme.spacing(2) }}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        anchorEl={accountMenuAnchor}
                        open={Boolean(accountMenuAnchor)}
                        onClose={() => setAccountMenuAnchor(null)}
                    >
                        <MenuItem>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Avatar style={{ width: 32, height: 32, marginRight: theme.spacing(2) }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography>
                                        {_.get(user, 'name.first', '')} {_.get(user, 'name.last', '')}
                                    </Typography>
                                    <Typography variant="caption">{_.get(user, 'email', '')}</Typography>
                                </div>
                            </div>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            component={Link}
                            button
                            to={'/dashboard/account'}
                            onClick={() => setAccountMenuAnchor(null)}
                        >
                            Manage Account
                        </MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Grid>
            <Grid
                style={{
                    margin: 0,
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: '100%',
                    overflow: 'hidden'
                }}
            >
                <Navigation visible={showNavigation} handleClose={() => setShowNavigation(false)} />
                <Routes>
                    {JSON.parse(process.env.REACT_APP_API_KEYS_ENABLED) && <Route path="api" element={<APIKeys />} />}
                    <Route path="account" element={<Account />} />
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </Grid>
        </Grid>
    );
}

export default Dashboard;

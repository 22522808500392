import { React, useContext, useState } from 'react';

import { useTheme } from '@emotion/react';
import { Grid, Paper, colors } from '@mui/material';

import AccountInfo from './AccountInfo';
import Password from './Password';
import LoggedInInfo from './LoggedInInfo';

import MobileContext from '../../contexts/Mobile/MobileContext';

function Account(props) {
    const theme = useTheme();
    const { isMobile } = useContext(MobileContext);

    return (
        <div
            style={{
                margin: theme.spacing(isMobile ? 0 : 2),
                padding: theme.spacing(isMobile ? 1 : 3),
                width: '100%',
                overflow: 'auto',
                backgroundColor: colors.grey[100],
                borderRadius: '10px'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper style={{ padding: theme.spacing(2) }}>
                        <AccountInfo />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper style={{ padding: theme.spacing(2) }}>
                        <Password />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper style={{ padding: theme.spacing(2) }}>
                        <LoggedInInfo />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default Account;

import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { getFormikError } from '../../utils/misc';

import { EMAIL_REGEX } from '../../constants';

import { useTheme } from '@emotion/react';
import { Grid, TextField, Typography, Button, CircularProgress } from '@mui/material';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import PhoneInput from '../InputComponents/PhoneInput';
import AuthContext from '../../contexts/Auth/AuthContext';
import HttpContext from '../../contexts/HTTP/HttpContext';
import SnackbarContext from '../../contexts/Snackbar/SnackbarContext';

import { useConfirm } from 'material-ui-confirm';

import { useNavigate } from 'react-router-dom';

function AccountInfo(props) {
    const { postJSON } = useContext(HttpContext);
    const { user, setUser, setAuthenticated } = useContext(AuthContext);
    const onSnackbar = useContext(SnackbarContext);
    const confirm = useConfirm();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [loadingAccountDelete, setLoadingAccountDelete] = useState(false);

    const handleUpdateAccountInfo = async (values) => {
        setLoading(true);
        const res = await postJSON(`/users/${user._id}/update`, values);
        if (res.status === 200) {
            setUser(res.data.user);
            onSnackbar('Successfully updated account');
        }
        setLoading(false);
    };

    const handleDeleteAccount = () => {
        confirm({
            description:
                'Are you sure you want to delete your account? This cannot be undone. Type DELETE below to confirm',
            confirmationKeyword: 'DELETE',
            confirmationKeywordTextFieldProps: { style: { marginTop: theme.spacing(1) } }
        })
            .then(async () => {
                setLoadingAccountDelete(true);

                const res = await postJSON(`/users/${user._id}/delete`);
                if (res.status === 200) {
                    onSnackbar('Your account was successfully deleted');
                    localStorage.removeItem('accessToken');
                    setAuthenticated(null);
                    navigate('/login');
                }
                setLoadingAccountDelete(false);
            })
            .catch(() => {}); //cancel pressed
    };

    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            email: _.get(user, 'email', ''),
            name: {
                first: _.get(user, 'name.first', ''),
                last: _.get(user, 'name.last', '')
            },
            phone: _.get(user, 'phone', ''),
            location: {
                description: _.get(user, 'location.description', '')
            }
        },
        validationSchema: Yup.object({
            email: Yup.string().required('You must enter a value').matches(EMAIL_REGEX, 'You must enter a valid email'),
            name: Yup.object({
                first: Yup.string().required('You must enter a value'),
                last: Yup.string().required('You must enter a value')
            }),
            phone: Yup.string().length(10, 'This should be 10 characters long').required('You must enter a value'),
            location: Yup.object({
                description: Yup.string().required('You must enter a value')
            })
        }),
        onSubmit: handleUpdateAccountInfo
    });

    return (
        <div>
            <Typography variant="h6">Account Information</Typography>
            <TextField
                fullWidth
                {...formik.getFieldProps('email')}
                label="Email"
                margin="normal"
                error={getFormikError(formik, 'email')}
                helperText={getFormikError(formik, 'email')}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                    fullWidth
                    {...formik.getFieldProps('name.first')}
                    label="First Name"
                    margin="normal"
                    style={{ marginRight: theme.spacing(2) }}
                    error={getFormikError(formik, 'name.first')}
                    helperText={getFormikError(formik, 'name.first')}
                />
                <TextField
                    fullWidth
                    {...formik.getFieldProps('name.last')}
                    label="Last Name"
                    margin="normal"
                    error={getFormikError(formik, 'name.last')}
                    helperText={getFormikError(formik, 'name.last')}
                />
            </div>
            <PhoneInput
                fullWidth
                {...formik.getFieldProps('phone')}
                label="Phone"
                margin="normal"
                variant="outlined"
                error={getFormikError(formik, 'phone')}
                helperText={getFormikError(formik, 'phone')}
            />
            <TextField
                fullWidth
                {...formik.getFieldProps('location.description')}
                label="Address"
                margin="normal"
                error={getFormikError(formik, 'location.description')}
                helperText={getFormikError(formik, 'location.description')}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing(2) }}>
                <Button variant="contained" onClick={handleDeleteAccount} disabled={loading} color="secondary">
                    Delete Account
                </Button>
                <Button variant="contained" onClick={formik.handleSubmit} disabled={loading || !formik.dirty}>
                    {loading && (
                        <CircularProgress size={18} color="inherit" style={{ marginRight: theme.spacing(1) }} />
                    )}
                    Save
                </Button>
            </div>
        </div>
    );
}

export default AccountInfo;

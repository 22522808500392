import React, { useContext, useEffect, useRef, useState } from 'react';

import _ from 'lodash';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAccessToken } from '../../utils/api';

import io from 'socket.io-client';
import SnackbarContext from '../../contexts/Snackbar/SnackbarContext';
import ContainerLoading from '../Loading/ContainerLoading';
import { CircularProgress } from '@mui/material';
import { maxWidth } from '@mui/system';

const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

function StreamDialog(props) {
    const { open, captureDevice, onClose } = props;

    const imgRef = useRef();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const onSnackbar = useContext(SnackbarContext);

    const [socket, setSocket] = useState(null);
    const [streamLoading, setStreamLoading] = useState(false);

    const handleConnect = async () => {
        setStreamLoading(true);
        //console.log(`%cuseStreamViewerSocketIO: opening socket connection`, 'color: blue');

        const accessToken = await getAccessToken();
        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_URL
                : process.env.REACT_APP_URL.replace(/.$/, '1');

        const socket = io(`${originURL}/stream-viewer`, {
            path: `${pathExtension}/socket.io`,
            extraHeaders: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        setSocket(socket);

        socket.emit('view-stream', captureDevice._id, (res) => {
            if (res !== 'ok') {
                onSnackbar(res, 'error');
            }
        });

        socket.on('stream-frame', (frame) => {
            if (streamLoading) setStreamLoading(false);
            //console.log('FRAME', frame);
            if (imgRef.current) {
                let imgObjectUrl = URL.createObjectURL(new Blob([frame], { type: 'image/jpeg' }));
                imgRef.current.src = imgObjectUrl;
            }
        });
        //socket.on('MSG', (msg) => console.log(msg));
    };

    useEffect(() => {
        if (open) {
            handleConnect();
        } else {
            if (!_.isNil(socket)) {
                //console.log(`%cclosed socket connection`, 'color: blue');
                socket.close();
                setSocket(null);
            }
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullScreen={fullScreen}>
            <DialogContent style={{ padding: 0 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {streamLoading && (
                        <span
                            style={{ padding: theme.spacing(8), width: 128, display: 'flex', justifyContent: 'center' }}
                        >
                            <CircularProgress size={64} />
                        </span>
                    )}
                    <img ref={imgRef} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default StreamDialog;

//https://stackoverflow.com/a/21508186
/*function draw2(imgData, canvas) {
    var ctx = canvas.getContext('2d');

    //var uInt8Array = new Uint8Array(imgData);
    var uInt8Array = imgData;
    var i = uInt8Array.length;
    var binaryString = [i];
    while (i--) {
        binaryString[i] = String.fromCharCode(uInt8Array[i]);
    }
    var data = binaryString.join('');

    var base64 = window.btoa(data);

    var img = new Image();
    img.src = 'data:image/png;base64,' + base64;
    img.onload = function () {
        //console.log("Image Onload");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
    img.onerror = function (stuff) {
        console.log('Img Onerror:', stuff);
    };
}*/

function draw(imgData, img) {
    let frame = URL.createObjectURL(new Blob([imgData], { type: 'image/jpeg' }));
    // /var b64imgData = btoa(imgData); //Binary to ASCII, where it probably stands for
    img.src = frame;
    //document.body.appendChild(img); //or append it to something else, just an example
}

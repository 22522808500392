import moment from 'moment-timezone';
import _ from 'lodash';

const timezone = process.env.REACT_APP_TIMEZONE;

export const formatDate = (date, format) => {
    return moment(date).tz(timezone).format(format);
};

export const getFormikError = (formik, path) => {
    return _.get(formik.touched, path, false) && _.get(formik.errors, path, false)
        ? _.get(formik.errors, path, false)
        : null;
};

export const isAdmin = function (user) {
    return _.get(user, 'roles', []).includes('admin');
};

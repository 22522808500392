import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { captureTypes } from '../../utils/constants';
import { getFormikError } from '../../utils/misc';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, InputAdornment, Switch, Checkbox, DialogContentText, Grid, MenuItem } from '@mui/material';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useFormik } from 'formik';
import * as Yup from 'yup';

function BulkUpdateDeviceDialog(props) {
    const { open, onClose, handleSubmit } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dailyUploadLimit: '',
            captureType: '',
            motionConfig: {
                width: '',
                height: '',
                framerate: '',
                threshold: ''
            },
            shouldUpdate: {
                dailyUploadLimit: false,
                captureType: '',
                motionConfig: {
                    width: false,
                    height: false,
                    framerate: false,
                    threshold: false
                }
            }
        },
        validationSchema: Yup.object({
            dailyUploadLimit: Yup.number().when('shouldUpdate.dailyUploadLimit', {
                is: true,
                then: (schema) => schema.required('You must enter a value')
            }),
            captureType: Yup.string().when('shouldUpdate.captureType', {
                is: true,
                then: (schema) => schema.required('You must enter a value')
            }),
            motionConfig: Yup.object({
                width: Yup.number()
                    .integer('This must be a whole number')
                    .when('shouldUpdate.motionConfig.width', {
                        is: true,
                        then: (schema) => schema.required('You must enter a value')
                    }),
                height: Yup.number()
                    .integer('This must be a whole number')
                    .when('shouldUpdate.motionConfig.height', {
                        is: true,
                        then: (schema) => schema.required('You must enter a value')
                    }),
                framerate: Yup.number()
                    .integer('This must be a whole number')
                    .min(2, 'This cannot be lower than 2')
                    .when('shouldUpdate.motionConfig.framerate', {
                        is: true,
                        then: (schema) => schema.required('You must enter a value')
                    }),
                threshold: Yup.number().when('shouldUpdate.motionConfig.threshold', {
                    is: true,
                    then: (schema) => schema.required('You must enter a value')
                })
            })
        }),
        onSubmit: (values) => {
            const updateObj = {};

            if (_.get(values, 'shouldUpdate.dailyUploadLimit')) {
                updateObj['configuration.dailyUploadLimit'] = values.dailyUploadLimit * 1000000;
            }
            if (_.get(values, 'shouldUpdate.motionConfig.width')) {
                updateObj['configuration.motionConfig.width'] = values.motionConfig.width;
            }
            if (_.get(values, 'shouldUpdate.motionConfig.height')) {
                updateObj['configuration.motionConfig.height'] = values.motionConfig.height;
            }
            if (_.get(values, 'shouldUpdate.motionConfig.framerate')) {
                updateObj['configuration.motionConfig.framerate'] = values.motionConfig.framerate;
            }
            if (_.get(values, 'shouldUpdate.motionConfig.threshold')) {
                updateObj['configuration.motionConfig.threshold'] = values.motionConfig.threshold;
            }

            if (_.get(values, 'shouldUpdate.captureType')) {
                updateObj['configuration.captureType'] = values.captureType;
                if (values.captureType === 'Image') {
                    updateObj['configuration.motionConfig.picture_output'] = 'on';
                    updateObj['configuration.motionConfig.movie_output'] = 'off';
                } else {
                    updateObj['configuration.motionConfig.picture_output'] = 'off';
                    updateObj['configuration.motionConfig.movie_output'] = 'on';
                }
            }

            handleSubmit(updateObj);
        }
    });

    useEffect(() => {
        if (open) {
            formik.handleReset();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={fullScreen}>
            <DialogTitle>Configure Devices</DialogTitle>
            <DialogContent style={{ paddingTop: theme.spacing(2) }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DialogContentText>
                            Use the switches to turn on fields you wish to update. Fields not turned on will be left
                            unchanged.
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formik.getFieldProps('dailyUploadLimit')}
                            type="number"
                            label="Daily Upload Limit (MB)"
                            disabled={!_.get(formik.values, 'shouldUpdate.dailyUploadLimit')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch {...formik.getFieldProps('shouldUpdate.dailyUploadLimit')} />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            error={getFormikError(formik, 'dailyUploadLimit')}
                            helperText={getFormikError(formik, 'dailyUploadLimit')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formik.getFieldProps('captureType')}
                            label="Capture Type"
                            disabled={!_.get(formik.values, 'shouldUpdate.captureType')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch {...formik.getFieldProps('shouldUpdate.captureType')} />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            error={getFormikError(formik, 'captureType')}
                            helperText={getFormikError(formik, 'captureType')}
                            fullWidth
                            select
                        >
                            {captureTypes.map((captureType) => (
                                <MenuItem key={captureType} value={captureType}>
                                    {captureType}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formik.getFieldProps('motionConfig.threshold')}
                            type="number"
                            label="Threshold (pixels)"
                            disabled={!_.get(formik.values, 'shouldUpdate.motionConfig.threshold')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch {...formik.getFieldProps('shouldUpdate.motionConfig.threshold')} />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            error={getFormikError(formik, 'motionConfig.threshold')}
                            helperText={getFormikError(formik, 'motionConfig.threshold')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formik.getFieldProps('motionConfig.framerate')}
                            type="number"
                            label="Frame Rate"
                            disabled={!_.get(formik.values, 'shouldUpdate.motionConfig.framerate')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch {...formik.getFieldProps('shouldUpdate.motionConfig.framerate')} />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            error={getFormikError(formik, 'motionConfig.framerate')}
                            helperText={getFormikError(formik, 'motionConfig.framerate')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formik.getFieldProps('motionConfig.width')}
                            type="number"
                            label="Image Width (pixels)"
                            disabled={!_.get(formik.values, 'shouldUpdate.motionConfig.width')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch {...formik.getFieldProps('shouldUpdate.motionConfig.width')} />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            error={getFormikError(formik, 'motionConfig.width')}
                            helperText={
                                getFormikError(formik, 'motionConfig.width') ||
                                'Valid range is camera dependent. Must be multiple of 8.'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formik.getFieldProps('motionConfig.height')}
                            type="number"
                            label="Image Height (pixels)"
                            disabled={!_.get(formik.values, 'shouldUpdate.motionConfig.height')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch {...formik.getFieldProps('shouldUpdate.motionConfig.height')} />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            error={getFormikError(formik, 'motionConfig.height')}
                            helperText={
                                getFormikError(formik, 'motionConfig.height') ||
                                'Valid range is camera dependent. Must be multiple of 8.'
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={formik.handleSubmit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BulkUpdateDeviceDialog;

import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';

import { getFormikError } from '../../utils/misc';

import { useTheme } from '@emotion/react';
import { TextField, Typography, Button, CircularProgress, List, ListItem, ListItemText } from '@mui/material';

import { formatDate } from '../../utils/misc';

import AuthContext from '../../contexts/Auth/AuthContext';
import HttpContext from '../../contexts/HTTP/HttpContext';

function AccountInfo(props) {
    const theme = useTheme();
    const { user } = useContext(AuthContext);
    const { get } = useContext(HttpContext);

    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState([]);

    const handleLoadSessions = async () => {
        setLoading(true);
        const res = await get(`/users/${user._id}/sessions`);
        if (res.status === 200) {
            setSessions(res.data.sessions);
        }
        setLoading(false);
    };

    useEffect(() => {
        handleLoadSessions();
    }, []);

    return (
        <div>
            <Typography variant="h6">Logged In Sessions</Typography>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(1) }}>
                    <CircularProgress />
                </div>
            ) : (
                <List>
                    {sessions.map((session, i) => {
                        const signInDate = _.get(session, 'createdAt');
                        const signedInStr = !_.isNil(signInDate)
                            ? formatDate(signInDate, 'YYYY MMM D - h:mm a')
                            : 'Unknown';
                        const lastAccessDate = _.get(session, 'lastAccessed');
                        const lastAccessedStr = !_.isNil(lastAccessDate)
                            ? formatDate(lastAccessDate, 'YYYY MMM D - h:mm a')
                            : 'Unknown';
                        return (
                            <ListItem key={i}>
                                <ListItemText
                                    primary={
                                        <>
                                            {_.get(session, 'deviceInfo.browser.name', 'Unknown')} -{' '}
                                            {_.get(session, 'deviceInfo.os.name', 'Unknown')} (
                                            {_.get(session, 'deviceInfo.ip')})
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <div>
                                                <b>Signed In:</b> {signedInStr}
                                            </div>
                                            <div>
                                                <b>Last Accessed:</b> {lastAccessedStr}
                                            </div>
                                        </>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </div>
    );
}

export default AccountInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import TextField from '@mui/material/TextField';
import { Input } from '@mui/material';

const TextMaskPhone = React.forwardRef(function TextMaskPhone(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            unmask={true}
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskPhone.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function PhoneInput(props) {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextMaskPhone
            }}
        />
    );
}

import React, { useContext, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';

import {
    Grid,
    IconButton,
    Paper,
    Typography,
    Tooltip,
    Chip,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Checkbox,
    TablePagination,
    TableFooter,
    Menu,
    MenuItem,
    Divider,
    CircularProgress
} from '@mui/material';
import * as Colors from '@mui/material/colors';

import { Close, Error, PlayArrow, Pause, Refresh, Visibility, History, Edit } from '@mui/icons-material';

import { MoreVert } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import MobileContext from '../../contexts/Mobile/MobileContext';
import HttpContext from '../../contexts/HTTP/HttpContext';

import { useTheme } from '@emotion/react';
import { formatDate, isAdmin } from '../../utils/misc';

import { useConfirm } from 'material-ui-confirm';
import SnackbarContext from '../../contexts/Snackbar/SnackbarContext';

const deviceActions = ['Pause Capture', 'Resume Capture', 'Restart Device'];

function DeviceTable(props) {
    const { devices, rowsSelected, onRowsSelected, onViewDeviceStream, onViewDeviceHistory, onUpdateDevice } = props;
    const theme = useTheme();
    const { isMobile } = useContext(MobileContext);
    const { postJSON } = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const confirm = useConfirm();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRowSelected = (_id, e) => {
        if (e.target.checked) {
            onRowsSelected([...rowsSelected, _id]);
        } else {
            onRowsSelected(_.filter(rowsSelected, (row) => row !== _id));
        }
    };

    const handleAllCheckbox = (e) => {
        if (e.target.checked) {
            const allIds = devices.map((device) => device._id);
            onRowsSelected(allIds);
        } else {
            onRowsSelected([]);
        }
    };

    const handleDeviceAction = async (actionName, captureDevice) => {
        let confirmStr = '',
            confirmNeeded = false;
        switch (actionName) {
            case 'Restart':
                confirmStr = 'Are you sure you want to restart this device?';
                confirmNeeded = true;
                break;
            case 'Pause':
                confirmStr = 'Are you sure you want to pause this device?';
                confirmNeeded = true;
                break;
            case 'Resume':
                break;
            case 'View Live':
                onViewDeviceStream(captureDevice);
                return;
            case 'View History':
                onViewDeviceHistory(captureDevice);
                return;
            case 'Update':
                onUpdateDevice(captureDevice);
                return;
            default:
                confirmStr = '';
        }

        if (confirmNeeded) {
            confirm({ description: confirmStr })
                .then(async () => {
                    const res = await postJSON(`/captureDevices/${captureDevice._id}/action`, { action: actionName });
                    if (res.status === 200) {
                        onSnackbar('Successfully completed request');
                    } else {
                        if (_.get(res, 'data.message')) {
                            onSnackbar(_.get(res, 'data.message'), 'error');
                        } else {
                            onSnackbar('Error processing the request', 'error');
                        }
                    }
                })
                .catch(() => {}); //cancel pressed
        } else {
            const res = await postJSON(`/captureDevices/${captureDevice._id}/action`, { action: actionName });
            if (res.status === 200) {
                onSnackbar('Successfully completed request');
            } else {
                if (_.get(res, 'data.message')) {
                    onSnackbar(_.get(res, 'data.message'), 'error');
                } else {
                    onSnackbar('Error processing the request', 'error');
                }
            }
        }
    };

    const allCheckboxState = useMemo(() => {
        if (_.isEmpty(devices)) {
            return { checked: false, indeterminate: false };
        }

        let countSelected = 0;

        for (const device of devices) {
            if (_.includes(rowsSelected, device._id)) {
                countSelected++;
            }
        }

        if (countSelected === devices.length) {
            return { checked: true, indeterminate: false };
        } else if (countSelected > 0) {
            return { checked: false, indeterminate: true };
        } else {
            return { checked: false, indeterminate: false };
        }
    }, [rowsSelected, devices]);

    return (
        <Paper variant="outlined" style={{ width: '100%', overflow: 'hidden' }}>
            <div
                style={{
                    marginTop: theme.spacing(1),
                    width: '100%',
                    overflow: 'auto'
                }}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={allCheckboxState.checked}
                                    indeterminate={allCheckboxState.indeterminate}
                                    onChange={handleAllCheckbox}
                                />
                            </TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>Device Id</TableCell>
                            <TableCell>Issue</TableCell>
                            <TableCell>Issue Description</TableCell>
                            <TableCell>Last Status Update</TableCell>
                            <TableCell>Upload Limit(MB)</TableCell>
                            <TableCell>Threshold</TableCell>
                            <TableCell>Image Width</TableCell>
                            <TableCell>Image Height</TableCell>
                            <TableCell>Frame rate</TableCell>
                            <TableCell>Capture Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ whiteSpace: 'nowrap' }}>
                        {_.isEmpty(devices) && (
                            <TableRow
                                style={
                                    {
                                        /*height: 100*/
                                    }
                                }
                            >
                                <TableCell colSpan={11} align="center">
                                    <i>No Entries</i>
                                </TableCell>
                            </TableRow>
                        )}
                        {devices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const selected = _.includes(rowsSelected, row._id);
                            const lastStatusMessage = _.get(row, 'lastStatusMessage');
                            return (
                                <TableRow
                                    key={row._id}
                                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={selected} onChange={(e) => handleRowSelected(row._id, e)} />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <ActionMenu
                                            captureDevice={row}
                                            options={deviceActions}
                                            onMenuItemClick={(action) => handleDeviceAction(action, row)}
                                        />
                                    </TableCell>
                                    <TableCell>{row.deviceId}</TableCell>
                                    <TableCell align="center">
                                        {row.hasIssue ? (
                                            <Error color="error" fontSize="small" />
                                        ) : (
                                            <Close color="disabled" fontSize="small" />
                                        )}
                                    </TableCell>
                                    <TableCell>{_.get(row, 'issues', []).join(', ')}</TableCell>
                                    <TableCell>
                                        {!_.isNil(lastStatusMessage)
                                            ? formatDate(lastStatusMessage, 'YYYY MMM D - h:mm a')
                                            : null}
                                    </TableCell>
                                    <TableCell align="right">
                                        {_.get(row, 'configuration.dailyUploadLimit', 0) / 1000000}
                                    </TableCell>
                                    <TableCell align="right">
                                        {_.get(row, 'configuration.motionConfig.threshold')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {_.get(row, 'configuration.motionConfig.width')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {_.get(row, 'configuration.motionConfig.height')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {_.get(row, 'configuration.motionConfig.framerate')}
                                    </TableCell>
                                    <TableCell>{_.get(row, 'configuration.captureType')}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={isMobile ? '' : 'Rows Per Page:'}
                component="div"
                count={devices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => {
                    setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value));
                    setPage(0);
                }}
            />
        </Paper>
    );
}

function ActionMenu({ options, onMenuItemClick, captureDevice }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loadingMotionStatus, setLoadingMotionStatus] = useState(false);
    const [deviceMotionStatus, setDeviceMotionStatus] = useState(null);
    const theme = useTheme();

    const { postJSON } = useContext(HttpContext);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOptionClicked = (option) => {
        handleClose();
        onMenuItemClick(option);
    };

    useEffect(() => {
        const handleGetMotionStatus = async () => {
            setLoadingMotionStatus(true);
            const res = await postJSON(`/captureDevices/${captureDevice._id}/action`, { action: 'Status' });
            let actionRes;
            if (res.status === 200) {
                actionRes = _.get(res, 'data.actionRes');
            }
            let statusMsg = 'UNKNOWN';
            if (_(actionRes).toLower().includes('active')) {
                statusMsg = 'ACTIVE';
            } else if (_(actionRes).toLower().includes('pause')) {
                statusMsg = 'PAUSED';
            }
            setDeviceMotionStatus(statusMsg);
            setLoadingMotionStatus(false);
        };

        if (open) {
            handleGetMotionStatus();
        }
    }, [open, captureDevice]);

    //const statusColor = Colors.red;

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem disableRipple>
                    <span>
                        Capture Status:{' '}
                        {loadingMotionStatus ? (
                            <CircularProgress size={18} style={{ marginLeft: theme.spacing(1) }} />
                        ) : (
                            deviceMotionStatus
                        )}
                    </span>
                </MenuItem>
                <MenuItem
                    onClick={() => handleOptionClicked('Resume')}
                    disabled={deviceMotionStatus === 'ACTIVE' || loadingMotionStatus}
                >
                    <PlayArrow style={{ marginRight: theme.spacing(1) }} />
                    Resume Capture
                </MenuItem>
                <MenuItem
                    onClick={() => handleOptionClicked('Pause')}
                    disabled={deviceMotionStatus === 'PAUSED' || loadingMotionStatus}
                >
                    <Pause style={{ marginRight: theme.spacing(1) }} /> Pause Capture
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleOptionClicked('Update')}>
                    <Edit style={{ marginRight: theme.spacing(1) }} />
                    Edit Configuration
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleOptionClicked('View Live')}>
                    <Visibility style={{ marginRight: theme.spacing(1) }} />
                    View Live
                </MenuItem>
                <MenuItem onClick={() => handleOptionClicked('View History')}>
                    <History style={{ marginRight: theme.spacing(1) }} />
                    View History
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleOptionClicked('Restart')}>
                    <Refresh style={{ marginRight: theme.spacing(1) }} />
                    Restart Device
                </MenuItem>
                {/*options.map((option) => (
                    <MenuItem key={option} onClick={() => handleOptionClicked(option)}>
                        {option}
                    </MenuItem>
                ))*/}
            </Menu>
        </div>
    );
}

export default DeviceTable;

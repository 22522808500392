import React, { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { Route, Routes, Navigate } from 'react-router-dom';

import Login from './Login/Login';
import Register from './Register/Register';
import Dashboard from './Dashboard/Dashboard';

import { CircularProgress, Grid } from '@mui/material';

import AuthContext from '../contexts/Auth/AuthContext';
import HttpContext from '../contexts/HTTP/HttpContext';
import MobileContext from '../contexts/Mobile/MobileContext';

import useMobile from '../hooks/useMobile';

function Pages() {
    const { get } = useContext(HttpContext);
    const { authenticated, setAuthenticated, user, setUser } = useContext(AuthContext);
    const { setIsMobile } = useContext(MobileContext);

    const [loading, setLoading] = useState(true);

    const {} = useMobile({ setIsMobile });

    const handleAuthentication = (res) => {
        // Handles authentication responses
        const accessToken = _.get(res, 'data.accessToken');
        if (!accessToken) return;

        localStorage.setItem('accessToken', accessToken);
        setAuthenticated(generateAuthenticatedObjFromToken(accessToken));
        setUser(_.get(res, 'data.user'));
    };

    useEffect(() => {
        // Hook to handle persistent logins
        if (authenticated) return;

        setLoading(true);

        (async () => {
            const res = await get('/checkStatus');
            if (res.status === 200) {
                const accessToken = localStorage.getItem('accessToken');
                setAuthenticated(generateAuthenticatedObjFromToken(accessToken));
                setUser(_.get(res, 'data.user'));
            }

            setLoading(false);
        })();
    }, [authenticated]);

    if (loading) {
        return (
            <Grid
                style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CircularProgress />
            </Grid>
        );
    }

    if (authenticated) {
        return (
            <Routes>
                <Route index element={<Navigate to="/dashboard/home" replace />} />
                <Route path="dashboard/*" element={<Dashboard />} />
                <Route path="*" element={<Navigate to="/dashboard/home" replace />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route index element={<Navigate to="/register" replace />} />
            <Route path="login" element={<Login authenticate={handleAuthentication} />} />
            <Route path="register" element={<Register authenticate={handleAuthentication} />} />
            <Route path="*" element={<Navigate to="/register" replace />} />
        </Routes>
    );
}

export default Pages;

function generateAuthenticatedObjFromToken(token) {
    const decodedToken = jwtDecode(token);

    return { roles: decodedToken.roles };
}

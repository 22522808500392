import React, { useMemo, useContext, useState } from 'react';
import _ from 'lodash';
import { useTheme } from '@emotion/react';
import { captureTypes } from '../../utils/constants';

import { Grid, IconButton, Paper, Typography, Tooltip, Chip } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import AddCircle from '@mui/icons-material/AddCircle';

import AuthContext from '../../contexts/Auth/AuthContext';
import { AccountCircle } from '@mui/icons-material';

import { formatDate, isAdmin } from '../../utils/misc';

import MobileContext from '../../contexts/Mobile/MobileContext';

import DeviceTable from './DeviceTable';

function DeviceGroupWidget(props) {
    const {
        deviceGroup,
        isUnassignedDeviceGroup,
        allDeviceGroups,
        rowsSelected,
        onRowsSelected,
        onEditDeviceGroup,
        onDeleteDeviceGroup,
        onRegisterDevice,
        onViewDeviceStream,
        onViewDeviceHistory,
        onUpdateDevice
    } = props;

    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const { isMobile } = useContext(MobileContext);

    /*const columns = useMemo(
        () => [
            {
                field: 'deviceId',
                headerName: 'Device Id',
                width: 150
            },
            {
                field: 'hasIssue',
                headerName: 'Issue',
                type: 'boolean',
                width: 100,
                renderCell: (params) => {
                    return (
                        params.row.hasIssue && (
                            <Tooltip title={_.get(params.row, 'issues', []).join(', ')}>
                                <Error color="error" />
                            </Tooltip>
                        )
                    );
                }
            },
            {
                field: 'issueDescription',
                headerName: 'Issue Description',
                valueGetter: (params) => {
                    return _.get(params.row, 'issues', []).join(', ');
                },
                flex: 1,
                minWidth: 100
            },
            {
                field: 'lastStatusMessage',
                headerName: 'Last Status Update',
                type: 'dateTime',
                flex: 1,
                minWidth: 100,
                valueGetter: (params) => {
                    const lastStatusMessage = _.get(params.row, 'lastStatusMessage');
                    return !_.isNil(lastStatusMessage) ? new Date(lastStatusMessage) : null;
                }
            },
            {
                field: 'configuration.dailyUploadLimit',
                headerName: 'Upload Limit(MB)',
                type: 'number',
                minWidth: 130,
                //editable: true
                //NOTE: need to use valueGetter on nested objects
                valueGetter: (params) => {
                    return _.get(params.row, 'configuration.dailyUploadLimit', 0) / 1000000;
                }
            },
            {
                field: 'configuration.motionConfig.threshold',
                headerName: 'Threshold',
                type: 'number',
                minWidth: 100,
                //NOTE: need to use valueGetter on nested objects
                valueGetter: (params) => {
                    return _.get(params.row, 'configuration.motionConfig.threshold');
                }
                //editable: true
            },
            {
                field: 'configuration.motionConfig.width',
                headerName: 'Image Width',
                type: 'number',
                minWidth: 100,
                //NOTE: need to use valueGetter on nested objects
                valueGetter: (params) => {
                    return _.get(params.row, 'configuration.motionConfig.width');
                }
                //editable: true
            },
            {
                field: 'configuration.motionConfig.height',
                headerName: 'Image Height',
                type: 'number',
                minWidth: 100,
                //NOTE: need to use valueGetter on nested objects
                valueGetter: (params) => {
                    return _.get(params.row, 'configuration.motionConfig.height');
                }
                //editable: true
            },
            {
                field: 'configuration.motionConfig.framerate',
                headerName: 'Frame Rate',
                type: 'number',
                minWidth: 100,
                //NOTE: need to use valueGetter on nested objects
                valueGetter: (params) => {
                    return _.get(params.row, 'configuration.motionConfig.framerate');
                }
                //editable: true
            },
            {
                field: 'configuration.captureType',
                headerName: 'Capture Type',
                type: 'singleSelect',
                minWidth: 100,
                //NOTE: need to use valueGetter on nested objects
                valueGetter: (params) => {
                    return _.get(params.row, 'configuration.captureType');
                },
                valueOptions: captureTypes
                //editable: true
            },

            {
                field: 'group',
                headerName: 'Group',
                type: 'singleSelect',
                valueOptions: allDeviceGroups.map((deviceGroup) => ({
                    value: deviceGroup._id,
                    label: deviceGroup.name
                })),
                //editable: true,
                minWidth: 100
            }
        ],
        [allDeviceGroups]
    );*/

    const captureDevices = useMemo(() => {
        let devices = _.get(deviceGroup, 'captureDevices', []);
        devices.forEach((captureDevice) => {
            captureDevice.id = captureDevice._id;
            captureDevice.group = deviceGroup._id;
        });
        return devices;
    }, [deviceGroup]);

    return (
        <Grid item xs={12} key={_.get(deviceGroup, '_id')}>
            <Paper style={{ padding: theme.spacing(2) }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                    <Typography
                        variant="h6"
                        style={{ marginRight: theme.spacing(1), fontWeight: isUnassignedDeviceGroup && 200 }}
                        color={isUnassignedDeviceGroup && 'textSecondary'}
                    >
                        {isUnassignedDeviceGroup ? 'Unassigned Devices' : _.get(deviceGroup, 'name')}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {!isUnassignedDeviceGroup && (
                                <div>
                                    <Tooltip disableFocusListener disableTouchListener title="Edit">
                                        <IconButton color="primary" onClick={() => onEditDeviceGroup(deviceGroup)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        disableFocusListener
                                        disableTouchListener
                                        title={
                                            !_.isEmpty(captureDevices) ? 'Only empty groups can be deleted' : 'Delete'
                                        }
                                    >
                                        <span>
                                            <IconButton
                                                color="secondary"
                                                disabled={!_.isEmpty(captureDevices)}
                                                onClick={() => onDeleteDeviceGroup(deviceGroup)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <div>
                            <Tooltip disableFocusListener disableTouchListener title="Register new device">
                                <IconButton
                                    variant="outlined"
                                    color="success"
                                    onClick={() => onRegisterDevice(isUnassignedDeviceGroup ? null : deviceGroup)}
                                    style={{ margin: theme.spacing(1) }}
                                >
                                    <AddCircle />
                                </IconButton>
                            </Tooltip>
                            {isAdmin(user) && !isUnassignedDeviceGroup && (
                                <Chip
                                    icon={<AccountCircle />}
                                    label={
                                        _.get(deviceGroup, 'user.name.first', '') +
                                        ' ' +
                                        _.get(deviceGroup, 'user.name.last', '')
                                    }
                                    color="primary"
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/*<DataGrid
                    autoHeight
                    style={{ marginTop: theme.spacing(1) }}
                    rows={captureDevices}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5
                            }
                        }
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowsSelected) => {
                        onRowsSelected(newRowsSelected);
                    }}
                    rowSelectionModel={rowsSelected}
                />*/}

                <DeviceTable
                    devices={captureDevices}
                    rowsSelected={rowsSelected}
                    onRowsSelected={onRowsSelected}
                    onViewDeviceStream={onViewDeviceStream}
                    onViewDeviceHistory={onViewDeviceHistory}
                    onUpdateDevice={onUpdateDevice}
                />
            </Paper>
        </Grid>
    );
}

export default DeviceGroupWidget;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, TextField } from '@mui/material';
import { useTheme } from '@emotion/react';

function ReassignDevicesDialog(props) {
    const { open, deviceGroups, unassignEnabled, onClose, onSubmit } = props;
    const theme = useTheme();

    const [groupId, setGroupId] = useState(null);

    useEffect(() => {
        if (open) {
            setGroupId(null);
        }
    }, [open, deviceGroups]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Reassign Devices</DialogTitle>
            <DialogContent style={{ paddingTop: theme.spacing(2) }}>
                <TextField
                    fullWidth
                    label="Group"
                    value={groupId}
                    onChange={(e) => setGroupId(e.target.value)}
                    autoFocus
                    select
                >
                    {unassignEnabled && (
                        <MenuItem value="UNASSIGNED">
                            <i>Unassign Devices</i>
                        </MenuItem>
                    )}
                    {deviceGroups.map((deviceGroup) => (
                        <MenuItem key={deviceGroup._id} value={deviceGroup._id}>
                            {deviceGroup.name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => onSubmit(groupId)} disabled={_.isNil(groupId)}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReassignDevicesDialog;
